import React from 'react'

const ImprintInfo = () => {
    return (
        <div className="imprint-info-area pt-100 pb-70">
            <div className="container">
                <div className="row">

                    <div className="col-lg-6 col-md-6 offset-lg-3 offset-md-3">
                        <div className="contact-info-box">
                            <div className="back-icon">
                                <i className='bx bx-book'></i>
                            </div>
                            <div className="icon">
                                <i className='bx bx-book'></i>
                            </div>
                            <h3>Legal</h3>
                            <p>Amtsgericht München <strong>HRB 243905</strong></p>
                            <p>VAT-ID: <strong>DE320189980</strong></p>
                            <p>Tax-ID: <strong>143/167/01176</strong></p>
                            <p>Representative: <strong>Oleksii Khilkevych</strong></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ImprintInfo